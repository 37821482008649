import React, { useEffect, useState } from "react";
import "../periksa.css";
import Image from "../logofix_white.png";
import { ref, onValue, getDatabase } from "firebase/database";
import Back from "./back";
import { useUserAuth } from "../contexts/AuthContext";
// import { Card } from "@nextui-org/react";
import { Button, Card } from "react-bootstrap";

function Periksa() {
  const [coroidalThickness, setCoroidalThickness] = useState(350);
  const [axialLength, setAxialLength] = useState(20);
  const [status, setStatus] = useState("");
  const { user } = useUserAuth();

  useEffect(() => {
    const db = getDatabase();
    const refDb = ref(db, "last_result_img");
    onValue(refDb, (snapshot) => {
      const data = snapshot.val();
      console.log("data", data);
      setAxialLength(Number(data?.AL).toFixed(2));
      setCoroidalThickness(Number(data?.CT).toFixed(1));
      setStatus(data?.status);
    });
  }, [user]);

  return (
    <div className="periksa">
      <Back />

      <div className="periksaimg">
        <img
          style={{ maxWidth: 500 }}
          src={Image}
          className="prkimage"
          alt="logo_glassist"
        />
      </div>

      <div className="periksa-cek">
        <form action="#" className="periksa-form">
          <div className="top">
            <h1 className="title">PERIKSA RETINOPATI DIABETIK</h1>
          </div>

          <div className="bottom">
            <Card>
              <Card.Header>Choroidal Thickness (µm)</Card.Header>
              <Card.Body>{coroidalThickness}</Card.Body>
            </Card>

            <Card>
              <Card.Header>Axial Length (mm)</Card.Header>
              <Card.Body>{axialLength}</Card.Body>
            </Card>
          </div>
          <Card style={{ marginTop: 12 }}>
            <Card.Header>
              Konsultasi Dokter
              <Button size="sm" style={{ marginLeft: 12 }}>
                Klik disini
              </Button>
            </Card.Header>
            <Card.Body>Status: {status}</Card.Body>
          </Card>
        </form>
      </div>
    </div>
  );
}

export default Periksa;
